import logo from "./logo.svg";
import "./App.css";
import { useState } from "react";
import CommingComponent from "./features/CommingComponent";
import HeaderComponent from "./features/Header/HeaderComponent";

function App() {
  const [data, setData] = useState("");
  const [name, setName] = useState("")
    return (
    <div className="App">
  
{/* <HeaderComponent></HeaderComponent> */}
<div>  Designhunk.com </div>
  <CommingComponent></CommingComponent>

    </div>
  );
}

export default App;
